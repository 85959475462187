<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách buổi kiểm tra</h3>
            </div>
            <div class="py-4">
                <el-button class="btn btn-success" @click="showImport()">
                    <i class="el-icon-upload2"></i>
                    Import điểm thi
                </el-button>
            </div>
        </div>

        <div class="card-body">
            <!--begin::Example-->
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                    

                        <el-select v-model="query.center_id" filterable class="w-100" placeholder="Chọn trung tâm"
                                   clearable>
                            <el-option
                                v-for="item in centers"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                <span style="float: left">{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <el-select v-model="query.branch_id" filterable class="w-100" placeholder="Chọn chi nhánh"
                                   clearable>
                            <el-option
                                v-for="item in branches"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                <span style="float: left">{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <el-input placeholder="Tên lớp" v-model="query.class_name"></el-input>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <el-input placeholder="Tìm giảng viên" v-model="query.teacher"></el-input>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <el-select v-model="query.type" filterable class="w-100" placeholder="Buổi kiểm tra"
                                   clearable>
                            <el-option
                                v-for="(item, index) in typeListTest"
                                :key="index"
                                :label="item.name + ' ' + item.value"
                                :value="[item.value, item.type]">
                                <span style="float: left">{{ item.name }} {{ item.value }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <date-picker placeholder="Chọn ngày kiếm tra" range v-model="query.exam_date_range"
                                     format="DD-MM-YYYY"
                                     valueType="YYYY-MM-DD"></date-picker>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="d-flex justify-content-between">
                        <button type="button"
                                class="btn btn-primary font-weight-bold mr-2"
                                :disabled="is_disable_search"
                                :class="{'spinner spinner-white spinner-left' : is_disable_search}"
                                @click="search"
                        >
                            Tìm kiếm
                        </button>
                    </div>
                </div>
            </div>

            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">Lớp học</th>
                            <th scope="col">Khóa học</th>
                            <th scope="col">Giảng viên</th>
                            <th scope="col">Chi nhánh</th>
                            <th scope="col">Buổi kiểm tra</th>
                            <th scope="col" class="min-w-150px">Lịch kiểm tra</th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(item, index) in exams.data" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.classroom ? item.classroom.name : '' }}</td>
                            <td>{{ item.course_name }}</td>
                            <td>{{ item.teacher ? item.teacher.name : '' }}</td>
                            <td>{{ item.branch ? item.branch.name : '' }}</td>
                            <td>{{ typeDefine[item.type] }} {{ item.type != 'mid' && item.type != 'last' ? item.numerical_order : '' }}</td>
                            <td>{{ item.date | formatDateTimeAsia }}</td>
                            <td class="d-flex">
                                <router-link
                                    v-if="currentUser.user.permission.includes(permission.CHI_TIET_BUOI_KIEM_TRA)"
                                    :to="{ name: 'exam-detail' , params: {id : item.classroom_id}, query: {list_test_id : item.id} }"
                                    title="Xem"
                                    class="btn btn-xs btn-icon mr-2 btn-outline-info">
                                    <i class="fa far fa-eye"></i>
                                </router-link>
<!--                                <ModalShowExamDetail :item="item"></ModalShowExamDetail>-->
<!--                                <router-link-->
<!--                                    :to="{ name: 'mark-by-class' , params: {class_id : item.classroom_id, list_test_id : item.id} }"-->
<!--                                    title="Chấm điểm"-->
<!--                                    class="btn btn-xs btn-icon mr-2 btn-outline-info">-->
<!--                                    <i class="fas far fa-edit"></i>-->
<!--                                </router-link>-->
                                <Attendance :item="item"></Attendance>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
        </div>
        <el-dialog title="Import điểm thi" :visible.sync="dialogImport">
            <div class="mb-2">
                <div>
                    <p for="">Bước 1: Tải file mẫu</p>
                    <div>
                        <el-button class="btn btn-primary" @click="exportFileMau">
                            <i class="el-icon-download"></i>
                            Tải xuống
                        </el-button>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <p for="">Bước 2: Tải file excel lên hệ thống</p>
                    <div class="mb-2">
                        <el-upload
                            ref="upload"
                            accept=".xlsx"
                            :limit="1"
                            list-type="text"
                            action=""
                            :file-list="fileList"
                            :on-change="handleChange"
                            :on-remove="handleRemove"
                            :auto-upload="false"
                            :multiple="false"
                        >
                            <el-button size="small" type="warning">
                                <i class="el-icon-upload2"></i>
                                Tải lên
                            </el-button>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    Lưu ý:
                </div>
                <div>
                    Nếu muốn xóa điểm kiểm tra của một học viên: Thêm các dữ liệu và để trống cột "Điểm".
                </div>
                <div>
                    Nếu muốn sửa điểm kiểm tra của một học viên: Import lại điểm mới. Sau khi import, dữ liệu mới sẽ
                    thay thế dữ liệu cũ.
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelImport">Cancel</el-button>
                <el-button :disabled="disable" :loading="loading" type="success" class="btn btn-success"
                           @click="submitUpload">Xác nhận
                </el-button>
            </div>
        </el-dialog>
        <el-dialog title="Import điểm thi" :visible.sync="logImport">
            <div>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</div>
            <table class="table" v-loading="loadingImport">
                <thead>
                <tr>
                    <th>Tên lớp</th>
                    <th>Loại buổi kiểm tra</th>
                    <th>Mã học viên</th>
                    <th>Lỗi</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(item, index) in listDataImport.dataError" :key="index">
                    <td>{{ item.ten_lop }}</td>
                    <td>{{ item.loai_buoi_kiem_tra }}</td>
                    <td>{{ item.ma_hoc_vien }}</td>
                    <td>
                        <p v-for="(item_prop, index_prop) in item?.error" :key="index_prop">
                            <span class="text-red">
                                {{ item_prop }}
                            </span>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <div slot="footer" class="dialog-footer">
                <el-button v-loading="loadingImport" @click="dialogImport = true, logImport= false">Quay lại </el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import ModalShowExamDetail from "./components/ModalShowExamDetail";
import {
    EXPORT_FILE_DEFAULT_TEST_SCORE,
    GET_LIST_TEST,
    IMPORT_EXAM,
    LIST_TYPE_TEST_EXAM
} from "../../../core/services/store/exam/exam.module";
import Attendance from "./components/Attendance";
import {read, utils} from "xlsx";
import error from "@/view/pages/error/Error";
import {mapGetters} from "vuex";

export default {
    name: "ExamIndex",
    components: {
        Attendance,
        ModalShowExamDetail,
        DatePicker
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    data() {
        return {
            page: 1,
            last_page: 1,
            is_disable_search: false,
            exams: [],
            centers: [],
            branches: [],
            query: {
                center_id: '',
                branch_id: '',
                class_name: '',
                teacher: '',
                type: '',
                status: '',
                exam_date_range: '',
            },
            types: [
                {
                    label: "Giữa khóa",
                    value: 'mid',
                },
                {
                    label: "Cuối khóa",
                    value: 'last',
                },
                {
                    label: "Tất cả",
                    value: 'all',
                },
            ],
            status: [
                {
                    label: "Đã chấm xong",
                    value: 2,
                },
                {
                    label: "Chưa chấm xong",
                    value: 1,
                },
                {
                    label: "Tất cả",
                    value: 0,
                },
            ],
            typeDefine: {
                mid: 'Thi giữa khóa',
                mini: 'Mini Test',
                unit: 'Unit Test',
                last: 'Cuối khóa',
            },
            statusDefine: {
                1: 'Chưa làm bài',
                2: 'Đã làm bài',
            },
            dialogImport: false,
            fileList: [],
            disable: false,
            loading: false,
            logImport: false,
            listDataImport: {},
            loadingImport: false,
            typeListTest: {}
        }
    },
    async created() {
        await this.getCenters();
        await this.getBranches();
        this.pullParamUrl();
        this.getListTest();
        this.getTypeListTest();
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý dạy học", route: 'exam-index'},
            {title: "Danh sách buổi kiểm tra"}
        ]);
    },
    methods: {
        search() {
            this.pushParamsUrl();
            this.getListTest();
        },
        clickCallback(obj) {
            this.page = obj;
            this.pushParamsUrl();
            this.getListTest()
        },
        pushParamsUrl() {
            this.$router.push({
                path: '', query: {
                    page: this.page,
                    ...this.query
                }
            })
        },
        pullParamUrl() {
            this.query.center_id = this.$route.query.center_id ? parseInt(this.$route.query.center_id) : '';
            this.query.branch_id = this.$route.query.branch_id ? parseInt(this.$route.query.branch_id) : '';
            this.query.class_name = this.$route.query.class_name;
            this.query.teacher = this.$route.query.teacher;
            this.query.status = this.$route.query.status;
            this.query.exam_date_range = this.$route.query.exam_date_range;
        },
        getListTest() {
            this.is_disable_search = true;
            this.$store.dispatch(GET_LIST_TEST, {
                page: this.page,
                ...this.query
            }).then((data) => {
                this.exams = data.data;
                this.last_page = data.data.last_page;
                this.is_disable_search = false;
            }).catch((error) => {
                console.log(error)
            })
        },
        async getCenters() {
            await this.$store.dispatch(GET_CENTERS, {
                limit: 10000,
                status: 1
            }).then((res) => {
                if (!res.error) {
                    this.centers = res.data.data;
                }
            })
        },
        async getBranches() {
            await this.$store.dispatch(GET_BRANCHES, {
                limit: 10000,
                status: 1
            }).then((res) => {
                if (!res.error) {
                    this.branches = res.data.data;
                }
            });
        },
        showImport() {
            this.dialogImport = true;
        },
        handleChange(file, fileList) {
            this.fileList = fileList
        },
        handleRemove() {
            this.fileList = []
        },
        cancelImport()
        {
            this.fileList = []
            this.dialogImport =false;
        },
        fileToJson(file) {
            return new Promise((res, rej) => {
                /* Boilerplate to set up FileReader */
                const reader = new FileReader()
                reader.onload = (e) => {
                    /* Parse data */
                    const bstr = e.target.result
                    const wb = read(bstr, {type: 'binary'})
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    /* Convert array of arrays */
                    const data = utils.sheet_to_json(ws)

                    res(data);
                }
                reader.onerror = (e) => {
                    rej(e);
                };
                reader.readAsBinaryString(file[0].raw)
            });
        },
        submitUpload() {
            if (!this.fileList.length) {
                this.$notify({
                    title: 'Thất bại',
                    message: 'Chưa có file nào được chọn',
                    type: 'error'
                })
            }
            this.fileToJson(this.fileList).then(dataToImport => {
                this.logImport = true;
                this.listDataImport = dataToImport;
                this.validateData(dataToImport);
                this.loadingImport = true;

                this.$store.dispatch(IMPORT_EXAM, this.listDataImport).then((res) => {
                  this.listDataImport = res.data;
                  if (res.data && res.data.dataError && res.data.dataError.length) {
                    this.$notify({
                      title: 'Không Thành công',
                      message: res.message,
                      type: 'error'
                    })
                    this.loadingImport = false;

                    return;
                  }
                  if(res.data) {
                        this.dialogImport = false;
                        this.logImport = false;
                        this.$notify({
                            title: 'Thành công',
                            message: res.message,
                            type: 'success'
                        })
                      this.fileList = []
                      this.loadingImport = false;
                    return;
                    }

                })
            })
        },
        exportFileMau() {
            this.$store.dispatch(EXPORT_FILE_DEFAULT_TEST_SCORE).then((res) => {
                this.$notify({
                    title: 'Thành công',
                    message: res.message,
                    type: 'success'
                })
            }).catch((error) => {
                this.$notify({
                    title: 'Thất bại',
                    message: error.data.message,
                    type: 'error'
                })
            })
        },
        validateData(dataToImport) {
            dataToImport.map((data) => {
                return data.error = [
                    typeof data.diem_lan_1 != 'number' && data.diem_lan_1 != null ? 'Số điểm không đúng định dạng số.' : '',
                ];
            });
            const foundDuplicateName = dataToImport.find((nnn, index) =>{
                return dataToImport.find((x, ind)=> x.ten_lop === nnn.ten_lop && index !== ind )
                    && dataToImport.find((x, ind)=> x.loai_buoi_kiem_tra === nnn.loai_buoi_kiem_tra
                    && index !== ind && x.ma_hoc_vien === nnn.ma_hoc_vien
                    && x.lan_thi === nnn.lan_thi)
                    && dataToImport.find((x, ind)=> x.ma_hoc_vien === nnn.ma_hoc_vien && index !== ind )
            })
            if (foundDuplicateName) {
                foundDuplicateName.error.push("Lặp dữ liệu");
            }
        },
        getTypeListTest()
        {
            this.$store.dispatch(LIST_TYPE_TEST_EXAM, {}).then((res) => {
                this.typeListTest = res.data;
            });
        }
    }
}
</script>

<style scoped>
.text-red {
    color: red;
}

.text-green {
    color: #0C9200;
}
</style>